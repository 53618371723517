import React from "react"
import Helmet from "react-helmet"

import Nav from "../../partials/Nav"
import PageSection from "../../layout/PageSection"
import PartnerHero from "../../partials/PartnerHero"
import CharityPartnerGrid from "../../components/CharityPartnerGrid"
import Footer from "../../partials/Footer"
import CookieBanner from "../../partials/CookieBanner"
import "../../styles.css"
const Charity: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Charities | Donate Now | Run For Heroes</title>
        <meta
          name="description"
          content="We support 5 health and well-being charities each year. Learn more about our charities and how you can support them."
        />
        <html lang="en" />
      </Helmet>
      <Nav
        activePath={"/our-partners/corporate"}
        childActivePath={"/our-partners/charity"}
      />
      <PageSection backgroundColor={"BLUE"}>
        <PartnerHero
          heading={"Charity Partners"}
          subCopy={
            "Each year we partner with incredible health and wellbeing charities to have the biggest impact."
          }
        />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <CharityPartnerGrid />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default Charity
