import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { LAYOUT, LINKS } from "../constants"

const DEFAULT_DATA = [
  {
    imgKey: "macmillan",
    imgAlt: "Macmillan cancer support",
    url: LINKS.MACMILLAN,
  },
  {
    imgKey: "greatOrmondStreet",
    imgAlt: "Great Ormond Street",
    url: LINKS.GREAT_ORMOND_STREET,
  },
  {
    imgKey: "chooseLove",
    imgAlt: "Choose Love",
    url: LINKS.CHOOSE_LOVE,
  },
  {
    imgKey: "nhsct",
    imgAlt: "NHSCT",

    url: LINKS.NHS_CHARITIES,
  },
  {
    imgKey: "mentalHealthUk",
    imgAlt: "Mental Health Uk",
    url: LINKS.MENTAL_HEALTH_UK,
  },
  {
    imgKey: "bhf",
    imgAlt: "BFH",
    url: LINKS.BRITISH_HEART_FOUNDATION,
  },
  {
    imgKey: "stc",
    imgAlt: "STC",
    url: LINKS.STC,
  },
  {
    imgKey: "alzheihmers",
    imgAlt: "Alzheihmers research UK",
    url: LINKS.ALZHEIHMERS,
  },
  { imgKey: "coppaFeel", imgAlt: "Coppa feel", url: LINKS.COPPA_FEEL },
]

interface IPartner {
  description: JSX.Element
  url: string
  imgAlt: string
  imgKey: string
}

interface IProps {
  rest?: object
  data?: Array<IPartner>
  subCopy?: boolean
}

const Root = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding-top: 20px;
`

const LogoContainer = styled.a`
  width: 320px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    width: 320px;
  }
`

interface ILogo {
  fluid: object
}
const Logo = styled(Img)<ILogo>`
  width: 100%;
  align-self: center;
`

const SubCopy = styled(BodyText)`
  max-width: 660px;
  margin: 40px auto;
`

const PartnerGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const PartnerGrid: React.FC<IProps> = ({
  data = DEFAULT_DATA,
  subCopy = true,
  ...rest
}) => {
  const imgData = useStaticQuery(graphql`
    query CharityPartnerGridQuery {
      nhsct: file(relativePath: { eq: "partners/charity/nhsct.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bhf: file(relativePath: { eq: "partners/charity/bhf.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mentalHealthUk: file(
        relativePath: { eq: "partners/charity/mental-health-uk.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      justGiving: file(
        relativePath: { eq: "partners/corporate/just-giving.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      zipcar: file(relativePath: { eq: "partners/corporate/zipcar.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      helloFresh: file(
        relativePath: { eq: "partners/corporate/hello-fresh.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      macmillan: file(relativePath: { eq: "partners/charity/macmillan.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      greatOrmondStreet: file(
        relativePath: { eq: "partners/charity/great-ormond-street.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      chooseLove: file(
        relativePath: { eq: "partners/charity/choose-love.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      stc: file(relativePath: { eq: "partners/charity/stc.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      alzheihmers: file(
        relativePath: { eq: "partners/charity/alzheihmers.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      coppaFeel: file(relativePath: { eq: "partners/charity/coppa-feel.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <PartnerGridContainer>
        {data.map((partner, i) => (
          <LogoContainer href={partner.url}>
            <Logo
              fluid={imgData[partner.imgKey].childImageSharp.fluid}
              alt={partner.imgAlt}
            />
          </LogoContainer>
        ))}
      </PartnerGridContainer>
      {subCopy && (
        <SubCopy type={"DARK"} alignment={"CENTER"}>
          <p>
            During 5k May, 5% of the total amount raised by each charity partner
            is given back to Run For Heroes to further it's charitable purpose.
            If you'd like more information, or would like to become a charity
            partner next year, please <Link to={"/contact-us"}>contact us</Link>
            .
          </p>
        </SubCopy>
      )}
    </Root>
  )
}

export default PartnerGrid
